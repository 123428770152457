.home-core {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 95vh;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  gap: 2rem;
  padding-bottom: 2rem;
}
.home-container {
  /* padding: 0 1rem; */
  width: 90%;
}
.home-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 15px;
  backdrop-filter: blur(50px);
  background-color: transparent;
  width: 100%;
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
}
.home-main2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background-color: red;
}
.home-hero-text-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.home-qrcode-input {
  height: 20px;
}
.home-bottom-button-container {
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.home-qrcode-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0;
  width: 100%;
  transition: 1s ease-in-out;
  min-height: 256px;
}
.home-partners-slideshow-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.home-hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.home-hero-logo {
  width: 100%;
  max-width: 300px;
  /* padding: 1rem; */
}
.home-hero {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.home-input {
  color: white;
  border: 1px solid var(--fg-color);
  box-shadow: 0px 0px 5px 1px var(--fg-color);
  width: 350px;
  height: 40px;
  margin: 4px 0;
  padding-left: 25px;
  font-size: 13px;
  letter-spacing: 0.15px;
  outline: none;
  font-family: var(--font-family-montserrat);
  background-color: transparent;
  backdrop-filter: blur(10px);
  transition: 0.25s ease;
  border-radius: 8px;
}
.home-hero-text {
  font-family: var(--font-family-montserrat);
  color: white;
  text-align: center;
  /* font-size: 100%; */
}
.home-mode-toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
}
/* From Uiverse.io by cssbuttons-io */
.home-mode-button {
  --color: var(--fg-color);
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  /* margin: 20px; */
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: black;
}

.home-mode-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.home-mode-button:hover {
  color: #fff;
}

.home-mode-button:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.home-mode-button:hover:before {
  top: -30px;
  left: -30px;
}
.home-wifi-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.home-mode-button:active:before,
.active {
  background: var(--fg-color);
  /* transition: background 0s; */
}

.home-bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  align-items: center;
}

.home-cssbuttons-io-button {
  background-color: var(--color-bg);
  backdrop-filter: blur(15px);
  color: var(--color-white);
  box-shadow: 0em 0em 0.5em 0.2em var(--color-iobutton);
  font-family: inherit;
  padding: 0.35em;
  padding-left: 1.2em;
  font-weight: 700;
  font-size: 20px;
  font-family: var(--font-family-montserrat);
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.5em;
  width: 12em;
  padding-right: 3.3em;
  cursor: pointer;
}

.home-icon {
  background-color: var(--color-bg2);
  margin-left: 1em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2.2em;
  border-radius: 5px;
  box-shadow: 0.1em 0.1em 0.6em 0.1em var(--color-iobutton);
  right: 0.3em;
  transition: all 0.3s;
}

.home-cssbuttons-io-button:hover .home-icon {
  width: calc(100% - 0.6em);
}

.home-cssbuttons-io-button .home-icon svg {
  width: 1.2em;
  transition: transform 0.3s;
  color: #ffffff;
}

.home-cssbuttons-io-button:hover .home-icon svg {
  transform: translateX(0.1em);
}

.home-cssbuttons-io-button:active .home-icon {
  transform: scale(0.95);
}

@media (max-width: 999px) {
  .home-cssbuttons-io-button {
    height: 2.5em;
  }
  .home-icon {
    height: 2em;
    width: 1.8em;
  }
}

.home-cards-row-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
  width: 100%;
  min-height: 80vh;
}
.home-cards-row-title {
  width: 100%;
  font-family: var(--font-family-akira);
  font-weight: 800;
  padding: 1rem 0rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  background-color: var(--fg-color);
  text-align: center;
}

.home-card-button {
  border-radius: 5px;
  background-color: transparent;
  box-shadow: 0px 0px 18px 0px var(--fg-color);
  width: 100%;
  height: 50px;
  padding: 0rem 2rem;
  cursor: pointer;
  color: var(--color-white);
  border: 2px solid var(--fg-color);
  font-family: var(--font-family-space);
  text-align: center;
  transition: 0.5s ease;
}
.home-card-button:hover {
  text-shadow: 0px 0px 10px var(--glow-2);
  background-color: var(--fg-color);
}
.home-card-button:active {
  box-shadow: 2px 2px 6px var(--fg-color), -2px -2px 6px var(--fg-color);
  transform: scale(0.97);
  transition: 0.25s;
}

.home-card-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 18px;
}
.home-card-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-cards-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 2rem;
  padding: 0 2rem;
}
#title {
  width: 100%;
  background-color: var(--fg-color);
}
.home-vertical-card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 325px;
  min-height: 400px;
  height: 100%;
  backdrop-filter: blur(15px);
  padding: 1rem;
  box-shadow: 0px 0px 18px 0px var(--fg-color);
  border-radius: 15px;
}

.home-vertical-card-userimage {
  width: 250px;
  height: 250px;
}
.home-vertical-card-top {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-vertical-card-bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  justify-content: center;
  align-items: center;
  text-align: center;
}
.home-vertical-card-bottom p {
  text-align: center;
}
.home-vertical-name-text {
  font-size: 25px;
  font-family: var(--font-family-akira);
  font-weight: 800;
  text-align: center;
  color: var(--fg-color);
  width: 100%;
  /* max-width: 250px; */
  text-transform: uppercase;
}

.home-vertical-info-text {
  font-size: medium;
  font-weight: 600;
}

@media (max-width: 500px) {
  .home-hero-text {
    font-size: 25px;
  }
  .home-hero {
    gap: 4rem;
  }
}
@media (max-width: 400px) {
  .home-hero-text {
    font-size: 22px;
  }
}
