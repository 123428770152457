.footer-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;
  color: white;
  backdrop-filter: blur(15px);
  box-shadow: 0px 0px 15px 0px rgb(255, 255, 255);
}
.footer-socialmedia-icons {
  display: flex;
  flex-direction: column;
}
.footer-socialedia-icon-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 150px;
}
.footer-bottom-text {
  text-align: center;
  width: 100%;
  padding: 0 2rem;
}
.footer-logo {
  width: 200px;
}
.footer-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
}
.footer-vertical-core {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
}
.footer-vertical-heading {
  font-family: var(--font-family-montserrat);
  font-weight: 800;
  font-size: 18px;
  margin: 0;
}
.footer-hr {
  width: 80%;
}
.footer-vertical-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 1rem;
  color: white;
}

@media (max-width: 650px) {
  .footer-vertical-core > :nth-child(2) {
    display: none;
  }
}

@media (max-width: 650px) {
  .footer-vertical-core {
    text-align: center;
  }
  .footer-vertical-container {
    align-items: center;
    justify-content: center;
  }
  .footer-socialedia-icon-container {
    justify-content: center;
    align-items: center;
  }
}
